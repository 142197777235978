<template>
  <v-container>
    <v-card-title class="red--text">
      403: Kein Zugriff
    </v-card-title>
  </v-container>
</template>

<script>
export default {
  name: 'E403'
}
</script>

<style scoped>

</style>
